import Vue from "vue";
import firebase from "firebase/app";

import "./assets/learnlink.global.styl";
import vuetify from "./plugins/vuetify";
import credentials from "./config/credentials";
import router from "./router";
import App from "./App.vue";

firebase.initializeApp(credentials.firebase);

Vue.config.productionTip = false;

// eslint-disable-next-line no-new
new Vue({
  el: "#app",
  router,
  vuetify,
  template: "<App/>",
  components: { App },
});
