

























































































import Vue from "vue";
import router from "@/router";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      roomName: "",
    };
  },
  methods: {
    openRoom() {
      // If the room name contains unsupported characters (., /, Æ, Ø, Å, etc), replace them with valid chars.
      const sanitizedPath = this.roomName
        .replace(new RegExp("æ", "g"), "ae")
        .replace(new RegExp("ø", "g"), "o")
        .replace(new RegExp("å", "g"), "aa")
        .replace(new RegExp(/[$-/:-?{-~!"^_`[\]]/, "g"), "");

      router.push("/" + sanitizedPath.toLowerCase());
    },
  },
});
