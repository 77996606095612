import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/:roomID",
      name: "room",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "room" */ "./views/Room.vue"),
    },
    {
      path: "*",
      component: () =>
        import(/* webpackChunkName: "Error404" */ "./views/Error404.vue"),
    },
  ],
});

export default router;
