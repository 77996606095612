<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import * as Integrations from "@sentry/integrations";
import * as Sentry from "@sentry/browser";
import credentials from "./config/credentials";
import LogRocket from "logrocket";
import * as version from "../version.json";

export default {
  name: "App",
  mounted() {
    const environment = credentials.firebase.projectId;

    if (window.location.hostname !== "localhost") {
      Sentry.init({
        dsn: "https://72275c8619644fd9b5483757fe3b5cb9@sentry.io/1438512",
        environment: environment.substring(10),
        release: version.version,
        integrations: [
          new Integrations.Vue({
            Vue,
            attachProps: true,
          }),
        ],
      });
      LogRocket.init("i77qy0/learnlink-online");
      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setExtra("sessionURL", sessionURL);
        });
      });
    }
  },
};
</script>
